
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, watchEffect } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { Input, Select, Radio } from '@/components/input-elements';
import { AddEndUser } from '../interfaces/index';
import { GET_COUNTRIES } from '@/modules/common/countries/graphql/Queries';
import { CREATE_END_USER } from '../graphql/Mutations';
import { GET_CUSTOMER_USER } from '../graphql/Queries';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { GET_CUSTOMER_ADDRESSES } from '../../Addresses/graphql/Queries';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'add Customer User',
    props: {
        customerAbilities: Object,
        customerData: Object
    },
    components: {
        Input,
        Select,
        InnerLoader
        // Radio
    },

    setup(props) {
        const store = useStore();
        const modal: any = ref();
        const uuid = ref('');
        const checkboxValue = ref(false);
        const defaultShipping = ref(false);
        const checkboxAddressValue = ref(false);
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const i18n = useI18n();
        const addEndUserForm = ref<null | HTMLFormElement>(null);
        const countries = ref([]) as Record<any, any>;
        const selectedAbilities = ref([]) as Record<any, any>;
        const selectedAddresses = ref([]) as Record<any, any>;
        const selectedRadios = ref() as Record<any, any>;
        const showForm = ref(false);
        const canManageAddresses = ref(false);
        const addresses = ref([]) as Record<any, any>;

        // Get Countries
        const getCountries = () => {
            countries.value = [];
            const countriesData = Apollo.readQuery({
                query: GET_COUNTRIES
            });

            const country = [] as Record<any, any>;
            countriesData.countries.forEach(element => {
                country.push({
                    label: element.name,
                    value: element.id,
                    locale: element.locale,
                    currency: element.currency
                });
            });

            countries.value = country;
        };

        const getAddresses = (page = 1, uuid: any = 0) => {
            Apollo.watchQuery({
                query: GET_CUSTOMER_ADDRESSES,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-and-network',
                variables: {
                    uuid: String(props?.customerData?.uuid)
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                }
                addresses.value = data?.customer_addresses?.data;
            });
        };

        const resetForm = () => {
            getCountries();
            getAddresses();
            uuid.value = '';
            emitter.emit('clearInput');
            addEndUserForm.value?.resetFields();
            checkboxValue.value = false;
            checkboxAddressValue.value = false;
            selectedAbilities.value = [];
            selectedAddresses.value = [];
        };

        const customer = ref<AddEndUser>({
            name: '',
            email: '',
            phone_number: '',
            password: '',
            password_confirmation: '',
            status: false,
            country_id: null
        });

        // Emitter To Open Model
        emitter.on('addEndUserUserAction', (id: string) => {
            showForm.value = true;
            resetForm();
            uuid.value = id;
            modal.value = new Modal(document.getElementById('modal_add_end_user')) as HTMLElement;
            modal.value.show();
        });

        const selectedAll = (event, abilities) => {
            if (event.target.checked == true) {
                checkboxValue.value = true;
                abilities.forEach(element => {
                    selectedAbilities.value.push(element.name);
                });
            } else {
                selectedAbilities.value = [];
            }
        };

        const selectedAllAddresses = (event, address) => {
            if (event.target.checked == true) {
                checkboxAddressValue.value = true;
                address.forEach(element => {
                    selectedAddresses.value.push(element.id);
                });
            } else {
                selectedAddresses.value = [];
            }
        };

        const submitHandler = () => {
            addEndUserForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        name: customer.value.name,
                        email: customer.value.email,
                        country: customer.value.country_id,
                        password: customer.value.password,
                        phone_number: customer.value.phone_number,
                        status: 1,
                        abilities: selectedAbilities.value,
                        addresses: selectedAddresses.value,
                        parent_customer_id: uuid.value,
                        radios: selectedRadios.value,
                        manage_address: canManageAddresses.value
                    };

                    loading.value = true;

                    //checking if email already exisit or not
                    const response = await store.dispatch('isEmailExist', formData);
                    if (response?.data != 1) {
                        await Apollo.mutate({
                            mutation: CREATE_END_USER,
                            variables: { input: formData },
                            update: (store, { data: { create_end_user } }) => {
                                const pervious_record = store.readQuery({
                                    query: GET_CUSTOMER_USER,
                                    variables: {
                                        page: 1,
                                        limit: 10,
                                        uuid: uuid.value
                                    }
                                }) as Record<any, any>;
                                store.writeQuery({
                                    query: GET_CUSTOMER_USER,
                                    variables: {
                                        page: 1,
                                        limit: 10,
                                        uuid: uuid.value
                                    },
                                    data: {
                                        customer_users: {
                                            ...pervious_record.customer_users,
                                            data: [create_end_user, ...pervious_record.customer_users.data]
                                        }
                                    }
                                });

                                loading.value = false;
                                modal.value.hide();
                                Notify.success(`${i18n.t('message.RECORD_ADDED_SUCCESSFULLY')}`);
                                // emitter.emit('endUserAdded');
                            }
                        }).catch(e => {
                            console.log(e);
                            loading.value = false;
                        });
                    } else {
                        loading.value = false;
                        Notify.error(`${i18n.t('message.EMAIL_ALREADY_EXISTS')}`);
                    }
                }
            });
        };

        watchEffect(async () => {
            customer.value.country_id = await props?.customerData?.user?.country?.id;
        });

        const closeModel = () => {
            modal.value.hide();
        };

        return {
            countries,
            customer,
            addEndUserForm,
            selectedAbilities,
            selectedAddresses,
            loading,
            closeModel,
            selectedAll,
            submitHandler,
            checkboxValue,
            checkboxAddressValue,
            canManageAddresses,
            addresses,
            selectedAllAddresses,
            defaultShipping,
            selectedRadios
        };
    }
});
