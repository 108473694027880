
import { defineComponent, ref, inject, onUnmounted, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import { GET_SUBSIDIARIES } from '../graphql/Queries';
import { useRoute } from 'vue-router';
import { DELETE_SUBSIDIARY } from '../graphql/Mutations';
import { useI18n } from 'vue-i18n';
import Table from '../../../../components/Table/Table.vue';
import { LOGIN_AS } from '../../customers/graphql/Queries';
import Search from '../../../../components/search/Search.vue';
// import InnerLoader from "../../../../components/InnerLoader.vue";

export default defineComponent({
    name: 'Subsidiary List',
    props: {
        customerData: Object
    },
    components: {
        Table,
        Search
        // InnerLoader
    },
    setup(props) {
        const store = useStore();
        const i18n = useI18n();
        const loading = ref(false);
        const loader = ref(false);
        const pagination = ref({}) as Record<any, any>;
        const emitter: any = inject('emitter');
        const customers = ref([]) as Record<any, any>;
        const route = useRoute();
        const observable: any = ref();
        const customer = ref(props?.customerData);
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref(0);

        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.EMAIL',
                key: 'email'
            },
            {
                label: 'message.STATUS',
                key: 'status'
            },
            {
                label: 'message.CREATED_AT',
                key: 'created_at'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        const handleCurrentChange = (val: number) => {
            clickHandler.value(val);
        };

        const addSubsidiary = () => {
            emitter.emit('addSubsidiaryAction', route.params.uuid);
        };

        const getSubsidiaries = (page = 1, reload = false, colName = null, type = null) => {
            loader.value = true;

            Apollo.watchQuery({
                query: GET_SUBSIDIARIES,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    parent_customer: customer.value?.id
                    // col: colName,
                    // type: type == true ? "asc" : "desc",
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                reload == true ? (currentPage.value = 1) : false;
                clickHandler.value = getSubsidiaries;
                customers.value = data.subsidiaries.data;
                pagination.value = data.subsidiaries;
                loader.value = false;
            });
        };

        const handleEdit = customer => {
            customer = { ...customer, page: pagination.value?.current_page };
            emitter.emit('editEndUserUserAction', customer);
        };

        const handleDelete = id => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_SUBSIDIARY,
                        variables: { customer_id: id },
                        update: (store, { data: { delete_subsidiary } }) => {
                            const pervious_record = store.readQuery({
                                query: GET_SUBSIDIARIES,
                                variables: {
                                    page: pagination.value.current_page,
                                    limit: 10,
                                    parent_customer: customer.value?.id
                                }
                            }) as Record<any, any>;

                            const data = pervious_record.subsidiaries.data.filter((t: any) => t.id !== delete_subsidiary);

                            store.writeQuery({
                                query: GET_SUBSIDIARIES,
                                data: {
                                    subsidiaries: {
                                        ...pervious_record.subsidiaries,
                                        data: [...data]
                                    }
                                },
                                variables: {
                                    page: pagination.value.current_page,
                                    limit: 10,
                                    parent_customer: customer.value?.id
                                }
                            });

                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        const handleLoginAs = (customer: Record<any, any>) => {
            loader.value = true;
            const login_as = Apollo.watchQuery({
                query: LOGIN_AS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    uuid: customer?.user.uuid
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                if (data?.login_as != null) window.open(`${process.env.VUE_APP_GEP_SHOP}/auth/attempt-login/${data.login_as}`, '_blank');
                login_as.unsubscribe();
                data = null;
                loader.value = false;
            });
        };

        emitter.on('endUserAdded', () => {
            getSubsidiaries(1);
        });

        onUnmounted(() => {
            observable.value?.unsubscribe();
        });

        onMounted(() => {
            getSubsidiaries(1);
        });
        return {
            customers,
            pagination,
            columns,
            loader,
            handleEdit,
            handleDelete,
            handleCurrentChange,
            addSubsidiary,
            customer,
            handleLoginAs,
            currentPage,
            getSubsidiaries
        };
    }
});
