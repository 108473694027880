import { gql } from "graphql-tag";

export const CUSTOMER_USER_FIELDS = gql`
  fragment CustomerFields on Customer {
    id
    uuid
    phone_number
    addresses {
      id
      parent_address_id
      name
      customer_id
      company
      phone_number
      address_1
      address_2
      city
      postal_code
      country_id
      default_payment_address
      default_shipping_address
      country{
        id
        name
      }
    }
    user {
      id
      uuid
      name
      email
      status
      country_id
      abilities
      
      image {
        id
        file_path
      }
      created_at
    }
  }
`;

export const CUSTOMER_SUBSIDIARIES_FIELDS = gql`
  fragment CustomerFields on Customer {
    id
    uuid
    user {
      id
      uuid
      name
      email
      status
      created_at
    }
  }
`;

export const PAGINATION = gql`
  fragment Pagination on CustomerPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;


export default { CUSTOMER_USER_FIELDS,CUSTOMER_SUBSIDIARIES_FIELDS, PAGINATION}