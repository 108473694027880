
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, computed } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { Select } from '@/components/input-elements';
import { CREATE_SUBSIDIARY_USER } from '../graphql/Mutations';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { GET_CUSTOMER_DROPDOWN_FILTER } from '@/modules/customer/customers/graphql/Queries';

export default defineComponent({
    name: 'add Subsidiary User',
    props: {
        customerData: Object
    },
    components: {
        Select,
        InnerLoader
    },

    setup(props) {
        const modal: any = ref();
        const uuid = ref('');
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const i18n = useI18n();
        const customers = ref([]) as Record<any, any>;
        const customerList = ref([]) as Record<any, any>;

        const getCustomers = (id = 0) => {
            Apollo.watchQuery({
                query: GET_CUSTOMER_DROPDOWN_FILTER,
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                errorPolicy: 'all',
                variables: {
                    page: 1,
                    limit: 10000,
                    origin: id
                    // col: colName,
                    // type: type == true ? "asc" : "desc",
                }
            }).subscribe(({ data }) => {
                customers.value = [];

                const filtered_data = data.customers_dropdown_filter.data?.filter(customer => customer?.id !== props?.customerData?.id);

                filtered_data.forEach(element => {
                    customers.value.push({
                        label: element?.user?.name,
                        value: element.id
                    });
                });
            });
        };

        const customer_data = computed(() => {
            return customers.value;
        });

        // Emitter To Open Model
        emitter.on('addSubsidiaryAction', (id: string) => {
            customerList.value = [];
            getCustomers(0);
            uuid.value = id;
            modal.value = new Modal(document.getElementById('modal_add_subsidiary')) as HTMLElement;
            modal.value.show();
        });

        const submitHandler = async () => {
            //checking if email already exisit or not
            if (customerList.value.length > 0) {
                loading.value = true;
                await Apollo.mutate({
                    mutation: CREATE_SUBSIDIARY_USER,
                    variables: { parent_customer: props?.customerData?.id, customer: JSON.stringify(customerList.value) },
                    update: (store, { data: { create_subsidiary_user } }) => {
                        loading.value = false;
                        modal.value.hide();
                        Notify.success(`${i18n.t('message.RECORD_ADDED_SUCCESSFULLY')}`);
                        emitter.emit('endUserAdded');
                    }
                }).catch(e => {
                    console.log(e);
                    loading.value = false;
                });
            }
        };

        const closeModel = () => {
            modal.value.hide();
        };

        return {
            loading,
            closeModel,
            submitHandler,
            getCustomers,
            customer_data,
            customerList
        };
    }
});
