
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, onMounted, computed } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { Select } from '@/components/input-elements';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { GET_CUSTOMER_DROPDOWN_FILTER } from '@/modules/customer/customers/graphql/Queries';
import { UPDATE_SUBSIDIARY_USER } from '../graphql/Mutations';

export default defineComponent({
    name: 'edit End User',
    props: {
        customerAbilities: Object,
        customerData: Object
    },
    components: {
        Select,
        InnerLoader
    },

    setup(props) {
        const modal: any = ref();
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const addEndUserForm = ref<null | HTMLFormElement>(null);
        const i18n = useI18n();
        const customerList = ref({});
        const customers = ref([]) as Record<any, any>;
        const subsidiary_id = ref(0);

        const resetForm = () => {
            emitter.emit('clearInput');
            addEndUserForm.value?.resetFields();
        };

        onMounted(() => {
            resetForm();
        });

        const getCustomers = (id = 0) => {
            Apollo.watchQuery({
                query: GET_CUSTOMER_DROPDOWN_FILTER,
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                errorPolicy: 'all',
                variables: {
                    page: 1,
                    limit: 10000,
                    origin: id
                }
            }).subscribe(({ data }) => {
                customers.value = [];

                data?.customers_dropdown_filter?.data.forEach(element => {
                    customers.value.push({
                        label: element?.user?.name,
                        value: element.id
                    });
                });
            });
        };

        const customer_data = computed(() => {
            return customers.value;
        });

        // Emitter To Open Model
        emitter.on('editEndUserUserAction', subsidiary_user => {
            // resetForm();

            customerList.value = {};
            customerList.value = subsidiary_user?.id;
            subsidiary_id.value = subsidiary_user?.id;
            getCustomers(subsidiary_id.value);

            modal.value = new Modal(document.getElementById('modal_edit_subsidiary')) as HTMLElement;
            modal.value.show();
        });

        const submitHandler = async () => {
            //checking if email already exisit or not
            if (customerList.value != 0 && subsidiary_id.value !== customerList.value) {
                loading.value = true;
                await Apollo.mutate({
                    mutation: UPDATE_SUBSIDIARY_USER,
                    variables: { parent_customer: props?.customerData?.id, customer: customerList.value, old_customer: subsidiary_id.value },
                    update: (store, { data: { create_subsidiary_user } }) => {
                        console.log('object :>> ', create_subsidiary_user);

                        loading.value = false;
                        modal.value.hide();
                        Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                        emitter.emit('endUserAdded');
                    }
                }).catch(e => {
                    console.log(e);
                    loading.value = false;
                });
            } else {
                modal.value.hide();
                Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                emitter.emit('endUserAdded');
            }
        };

        const closeModel = () => {
            modal.value.hide();
        };

        return {
            addEndUserForm,
            loading,
            submitHandler,
            customerList,
            customers,
            customer_data,
            subsidiary_id,
            closeModel
        };
    }
});
