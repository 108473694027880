import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Subsidiary = _resolveComponent("Subsidiary", true)!
  const _component_AddSubsidiary = _resolveComponent("AddSubsidiary")!
  const _component_EditSubsidiary = _resolveComponent("EditSubsidiary")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Subsidiary, { customerData: _ctx.customer }, null, 8, ["customerData"]),
    _createVNode(_component_AddSubsidiary, { customerData: _ctx.customer }, null, 8, ["customerData"]),
    _createVNode(_component_EditSubsidiary, { customerData: _ctx.customer }, null, 8, ["customerData"])
  ], 64))
}