import { gql } from "graphql-tag";
import { CUSTOMER_USER_FIELDS, CUSTOMER_SUBSIDIARIES_FIELDS } from "./Fragments";

export const CREATE_SUBSIDIARY_USER = gql`
	mutation CreateSubsidiaryUser($parent_customer:Int , $customer: String) {
		create_subsidiary_user(parent_customer:$parent_customer , customer: $customer)
	}
`;

export const UPDATE_SUBSIDIARY_USER = gql`
	mutation UpdateSubsidiaryUser($parent_customer:Int , $customer: Int , $old_customer:Int) {
		update_subsidiary(parent_customer:$parent_customer , customer: $customer , old_customer: $old_customer)
	}
`;

export const UPDATE_END_USER = gql`
	mutation UpdateEndUser($input: EndUserInput!) {
		update_end_user(customer: $input) {
			...CustomerFields
		}
	}
	${CUSTOMER_USER_FIELDS}
`;

export const DELETE_END_USER = gql`
	mutation DeleteEndUser($customer_id: Int) {
		delete_end_user(customer_id: $customer_id)
	}
`;

export const DELETE_SUBSIDIARY = gql`
	mutation DeleteSubsidiary($customer_id: Int) {
		delete_subsidiary(customer_id: $customer_id)
	}
`;

export default { CREATE_SUBSIDIARY_USER, UPDATE_END_USER, DELETE_END_USER, DELETE_SUBSIDIARY, UPDATE_SUBSIDIARY_USER };
