
import { defineComponent } from 'vue';
import Subsidiary from '../components/Subsidiary.vue';
import AddSubsidiary from '../components/AddSubsidiary.vue';
import EditSubsidiary from '../components/EditSubsidiary.vue';
export default defineComponent({
    props: {
        customer: Object
    },
    components: {
        Subsidiary,
        AddSubsidiary,
        EditSubsidiary
    }
});
