
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, watchEffect, onMounted } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { Input, Select } from '@/components/input-elements';
import { EditEndUser } from '../interfaces/index';
import { GET_COUNTRIES } from '@/modules/common/countries/graphql/Queries';
import { UPDATE_END_USER } from '../graphql/Mutations';
import { GET_CUSTOMER_USER } from '../graphql/Queries';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { GET_CUSTOMER_ADDRESSES } from '../../Addresses/graphql/Queries';

export default defineComponent({
    name: 'edit End User',
    props: {
        customerAbilities: Object,
        customerData: Object
    },
    components: {
        Input,
        Select,
        InnerLoader
    },

    setup(props) {
        const modal: any = ref();
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const addEndUserForm = ref<null | HTMLFormElement>(null);
        const countries = ref([]) as Record<any, any>;
        const selectedAbilities = ref([]) as Record<any, any>;
        const route = useRoute();
        const i18n = useI18n();
        const page = ref(0);
        const allSelectAbility = ref(false);
        const allSelectAddress = ref(false);
        const canManageAddresses = ref(false);
        const checkboxValue = ref(false);
        const checkboxAddressValue = ref(false);
        const selectedAddresses = ref([]) as Record<any, any>;
        const addresses = ref([]) as Record<any, any>;
        const selectedRadios = ref() as Record<any, any>;

        // Get Countries
        const getCountries = () => {
            countries.value = [];
            const countriesData = Apollo.readQuery({
                query: GET_COUNTRIES
            });

            const country = [] as Record<any, any>;
            countriesData.countries.forEach(element => {
                country.push({
                    label: element.name,
                    value: element.id,
                    locale: element.locale,
                    currency: element.currency
                });
            });

            countries.value = country;
        };

        const setSelectedRadio = id => {
            selectedRadios.value = id;
        };

        const getAddresses = (page = 1, uuid: any = 0) => {
            Apollo.watchQuery({
                query: GET_CUSTOMER_ADDRESSES,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-and-network',
                variables: {
                    uuid: String(props?.customerData?.uuid)
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                }
                addresses.value = data?.customer_addresses?.data;
            });
        };

        const resetForm = () => {
            getCountries();
            getAddresses();
            selectedAbilities.value = [];
            allSelectAbility.value = false;
            allSelectAddress.value = false;
            checkboxValue.value = false;
            checkboxAddressValue.value = false;
            page.value = 0;
            emitter.emit('clearInput');
            addEndUserForm.value?.resetFields();
            selectedAddresses.value = [];
        };

        const customer = ref<EditEndUser>({
            id: null,
            name: '',
            email: '',
            phone_number: '',
            password: '',
            password_confirmation: '',
            status: false,
            country_id: null,
            user_id: null
        });

        onMounted(() => {
            resetForm();
        });

        // Emitter To Open Model
        emitter.on('editEndUserUserAction', end_user => {
            // resetForm();
            if (end_user?.addresses.length > 0) {
                canManageAddresses.value = true;

                end_user?.addresses.forEach(element => {
                    const parentAddressMatch = addresses.value.find(address => address.id === element.parent_address_id);
                    if (parentAddressMatch) {
                        selectedAddresses.value.push(element.parent_address_id);
                    }

                    element.default_shipping_address == 1 ? (selectedRadios.value = element.parent_address_id) : null;
                });

                if (end_user?.addresses?.length == addresses.value?.length) {
                    allSelectAddress.value = true;
                }
            }

            page.value = end_user.page;
            customer.value.id = end_user.id;
            customer.value.user_id = end_user.user.id;
            customer.value.name = end_user.user.name;
            customer.value.email = end_user.user.email;
            customer.value.country_id = end_user.user?.country_id;
            customer.value.phone_number = end_user.phone_number;
            customer.value.status = end_user.user.status == 1 ? true : false;

            const abilities = JSON.parse(end_user.user.abilities);
            if (abilities.length > 0) {
                abilities.forEach(element => {
                    selectedAbilities.value.push(element.name);
                });

                if (abilities.length == props.customerAbilities?.length) {
                    allSelectAbility.value = true;
                }
            }

            modal.value = new Modal(document.getElementById('modal_edit_end_user')) as HTMLElement;
            modal.value.show();
        });

        const selectedAllAddresses = (event, address) => {
            if (event.target.checked == true) {
                checkboxAddressValue.value = true;
                address.forEach(element => {
                    selectedAddresses.value.push(element.id);
                });
            } else {
                selectedAddresses.value = [];
            }
        };

        const selectedAll = (event, abilities) => {
            if (event.target.checked == true) {
                abilities.forEach(element => {
                    selectedAbilities.value.push(element.name);
                });
            } else {
                selectedAbilities.value = [];
            }
        };

        const submitHandler = () => {
            addEndUserForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        name: customer.value.name,
                        email: customer.value.email,
                        country: customer.value.country_id,
                        password: customer.value.password,
                        phone_number: customer.value.phone_number,
                        status: customer.value.status == true ? 1 : 0,
                        abilities: selectedAbilities.value,
                        id: customer.value.id,
                        user_id: customer.value.user_id,
                        addresses: selectedAddresses.value,
                        radios: selectedRadios.value,
                        manage_address: canManageAddresses.value
                    };

                    loading.value = true;
                    await Apollo.mutate({
                        mutation: UPDATE_END_USER,
                        variables: { input: formData },
                        update: (store, { data: { update_end_user } }) => {
                            const pervious_record = store.readQuery({
                                query: GET_CUSTOMER_USER,
                                variables: {
                                    page: page.value,
                                    limit: 10,
                                    uuid: route.params.uuid
                                }
                            }) as Record<any, any>;

                            store.writeQuery({
                                query: GET_CUSTOMER_USER,
                                variables: {
                                    page: page.value,
                                    limit: 10,
                                    uuid: route.params.uuid
                                },
                                data: {
                                    customer_users: {
                                        ...pervious_record.customer_users
                                    },
                                    variables: {
                                        id: update_end_user.id
                                    }
                                }
                            });

                            loading.value = false;
                            modal.value.hide();
                            Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                            // emitter.emit('endUserAdded');
                        }
                    }).catch(e => {
                        console.log(e);
                        loading.value = false;
                    });
                }
            });
        };

        watchEffect(() => {
            customer.value.country_id = props?.customerData?.user?.country?.id;
        });

        return {
            countries,
            customer,
            addEndUserForm,
            selectedAbilities,
            allSelectAbility,
            allSelectAddress,
            loading,
            selectedAll,
            submitHandler,
            canManageAddresses,
            addresses,
            selectedAllAddresses,
            selectedAddresses,
            selectedRadios,
            setSelectedRadio
        };
    }
});
