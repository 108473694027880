import { gql } from "graphql-tag";
import {PAGINATION, CUSTOMER_USER_FIELDS , CUSTOMER_SUBSIDIARIES_FIELDS} from "./Fragments";

export const GET_SUBSIDIARIES = gql`
    query GetSubsidiaries($page: Int, $limit: Int, $parent_customer:Int) {
        subsidiaries(limit:$limit, page:$page, parent_customer:$parent_customer){
            ...Pagination
            data {
                ...CustomerFields
            }
        }
    }
    ${CUSTOMER_SUBSIDIARIES_FIELDS},
    ${PAGINATION},
`;


export default { GET_SUBSIDIARIES }